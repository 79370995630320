import { defineComponent } from 'vue';
import { german } from '@rose/common-ui';
import { settingsHeuteColumnsDefs } from '@/components/columns/settings/heute';
export default defineComponent({
  props: {
    items: {
      type: [],
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data: function data() {
    return {
      localeText: german,
      gridOptions: {},
      gridApi: {},
      gridColumnApi: {},
      columnDefs: settingsHeuteColumnsDefs(),
      rowClass: 'row-class-even'
    };
  },
  created: function created() {
    this.gridOptions = {
      rowHeight: 50,
      defaultColDef: {
        resizable: true,
        cellStyle: {
          display: 'flex',
          'justify-content': 'start',
          'align-items': 'center'
        } // autoHeight: true,

      },
      suppressCellFocus: true
    };
  },
  methods: {
    onGridReady: function onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setDomLayout('autoHeight');
    },
    onCellClicked: function onCellClicked(event) {
      this.$log.debug('cell clicked:', event);
    },
    getRowClass: function getRowClass(params) {
      if (params.node.rowIndex % 2 === 0) {
        return 'row-class-odd';
      }
    }
  }
});