import { IRoseAGGridColumn, getHelpTextFromFeldKey, getHeuteConditionsFromFeldKey } from '@rose/common-ui';
import { IAlarmeSetting } from '@rose/types';

export function settingsHeuteColumnsDefs(): IRoseAGGridColumn<IAlarmeSetting>[] {
  return [
    {
      headerName: 'Feld',
      field: 'feldName',
      sortable: true,
      sort: 'asc',
      width: 160,
    },
    {
      headerName: 'Hilfe',
      field: 'feldKey',
      valueFormatter: params => getHelpTextFromFeldKey(params.data?.feldKey),
      cellRenderer: 'HelpTooltipCell',
      width: 60,
      sortable: true,
    },
    {
      headerName: 'Einstellung',
      field: 'feldKey',
      valueGetter: params => getHeuteConditionsFromFeldKey(params.data?.feldKey),
      cellRenderer: 'ConditionsCell',
      resizable: true,
      width: 280,
    },
    {
      headerName: 'Empfang',
      field: 'empfang',
      cellStyle: { 'justify-content': 'center' },
      cellRenderer: 'CounterCell',
      resizable: true,
      flex: 1,
      minWidth: 210,
    },
    {
      headerName: 'Prophylaxe',
      field: 'prophylaxe',
      cellStyle: { 'justify-content': 'center' },
      cellRenderer: 'CounterCell',
      resizable: true,
      flex: 1,
      minWidth: 210,
    },
    {
      headerName: 'Zahnarzt',
      field: 'zahnarzt',
      cellStyle: { 'justify-content': 'center' },
      cellRenderer: 'CounterCell',
      resizable: true,
      flex: 1,
      minWidth: 210,
    },
    {
      headerName: 'Abrechnung',
      field: 'abrechnung',
      cellStyle: { 'justify-content': 'center' },
      cellRenderer: 'CounterCell',
      resizable: true,
      flex: 1,
      minWidth: 210,
    },
  ];
}
