import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  props: {
    list: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    ohneTerminbehandler: {
      type: Array,
      "default": function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {};
  },
  computed: {},
  methods: {
    updateGruppenFilter: function updateGruppenFilter(data) {
      editSettingsStore.commit.updateGruppenFilter(data);
    }
  }
});