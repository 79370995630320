import { defineComponent } from 'vue';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
import { toInteger } from 'lodash';
export default defineComponent({
  data: function data() {
    return {};
  },
  computed: {
    warteZimmerMin: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.warnung.warteZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          warteZimmer: toInteger(value)
        });
      }
    },
    warteZimmerMax: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.maximum.warteZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          warteZimmer: toInteger(value)
        });
      }
    },
    behandlungsZimmerMin: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.warnung.behandlungsZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          behandlungsZimmer: toInteger(value)
        });
      }
    },
    behandlungsZimmerMax: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.maximum.behandlungsZimmer;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          behandlungsZimmer: toInteger(value)
        });
      }
    },
    terminMin: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.warnung.termin;
      },
      set: function set(value) {
        this.updateWartezeitenWarnung({
          termin: toInteger(value)
        });
      }
    },
    terminMax: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.maximum.termin;
      },
      set: function set(value) {
        this.updateWartezeitenMaximum({
          termin: toInteger(value)
        });
      }
    },
    terminVerspaetet: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.wartezeiten) === null || _a === void 0 ? void 0 : _a.minimumVerspaetungInMinuten;
      },
      set: function set(value) {
        editSettingsStore.commit.updateWarteZeitenVerspaetung(toInteger(value));
      }
    }
  },
  methods: {
    updateWartezeitenWarnung: function updateWartezeitenWarnung(data) {
      editSettingsStore.commit.updateWartezeitenWarnung(data);
    },
    updateWartezeitenMaximum: function updateWartezeitenMaximum(data) {
      editSettingsStore.commit.updateWartezeitenMaximum(data);
    }
  }
});