import { defineComponent } from 'vue';
import HelpTooltip from '@/components/HelpTooltip.vue';
import { behandlerStore } from '@/state/behandlerStore';
import Helptext from '@/components/Helptext.vue';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    HelpTooltip: HelpTooltip,
    Helptext: Helptext
  },
  data: function data() {
    return {};
  },
  computed: {
    pvs: function pvs() {
      return editSettingsStore.state.editSettings.pvs;
    },
    stichworte: function stichworte() {
      return staticDataMetricsStore.state.params.stichworte;
    },
    terminbehandler: function terminbehandler() {
      return behandlerStore.getters.terminBehandler;
    },
    terminarten: function terminarten() {
      return staticDataMetricsStore.state.terminarten;
    },
    disabeldAlarmsStichworte: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsStichworte;
      },
      set: function set(items) {
        editSettingsStore.commit.updateDisabledAlarmsStichworte(items);
      }
    },
    disabledAlarmsTerminbehandler: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsTerminbehandler;
      },
      set: function set(items) {
        editSettingsStore.commit.updateDisabledAlarmsTerminbehandler(items);
      }
    },
    disabledAlarmsTerminarten: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsTerminarten;
      },
      set: function set(items) {
        editSettingsStore.commit.updateDisabledAlarmsTerminarten(items);
      }
    },
    disabledAlarmsUeberweiser: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsUeberweiser;
      },
      set: function set(active) {
        editSettingsStore.commit.updateDisabledAlarmsUeberweiser(active);
      }
    },
    disabledAlarmsTerminstatusAbgesagt: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsTerminstatusAbgesagt;
      },
      set: function set(active) {
        editSettingsStore.commit.updateDisabledAlarmsTerminstatusAbgesagt(active);
      }
    },
    disabledAlarmsTerminstatusNichtErschienen: {
      get: function get() {
        return editSettingsStore.getters.disabledAlarmsTerminstatusNichtErschienen;
      },
      set: function set(active) {
        editSettingsStore.commit.updateDisabledAlarmsTerminstatusNichtErschienen(active);
      }
    }
  }
});