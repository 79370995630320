import "core-js/modules/es.array.map.js";
import { defineComponent } from 'vue';
import HeuteGruppenFilterPopup from '@/components/settings/Heute/HeuteGruppenFilterPopup.vue';
import { behandlerStore } from '@/state/behandlerStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  components: {
    HeuteGruppenFilterPopup: HeuteGruppenFilterPopup
  },
  data: function data() {
    return {};
  },
  computed: {
    termingruppenNamen: function termingruppenNamen() {
      return staticDataMetricsStore.getters.termingruppenNamen;
    },
    zahnaerzteNamen: function zahnaerzteNamen() {
      return behandlerStore.getters.zahnaerzte.map(function (v) {
        return v.displayText;
      });
    },
    pzrNamen: function pzrNamen() {
      return behandlerStore.getters.pzr.map(function (v) {
        return v.displayText;
      });
    },
    pzrNoTerminBehandler: function pzrNoTerminBehandler() {
      var _a;

      return (_a = editSettingsStore.getters.pzrNoTerminBehandler) === null || _a === void 0 ? void 0 : _a.map(function (v) {
        return v.displayText;
      });
    },
    zaNoTerminBehandler: function zaNoTerminBehandler() {
      var _a;

      return (_a = editSettingsStore.getters.zaNoTerminBehandler) === null || _a === void 0 ? void 0 : _a.map(function (v) {
        return v.displayText;
      });
    },
    gfempfangtg: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.empfang.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gfempfangza: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.empfang.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gfempfangpzr: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.empfang.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'empfang',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxetg: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.prophylaxe.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxeza: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.prophylaxe.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gprophylaxepzr: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.prophylaxe.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'prophylaxe',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarzttg: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.zahnarzt.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarztza: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.zahnarzt.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpzahnarztpzr: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.zahnarzt.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'zahnarzt',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungtg: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.abrechnung.termingruppen.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'termingruppen',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungza: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.abrechnung.za.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'za',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    },
    gpabrechnungpzr: {
      get: function get() {
        var _a;

        return (_a = editSettingsStore.getters.gruppenfilter) === null || _a === void 0 ? void 0 : _a.abrechnung.pzr.anzeigen;
      },
      set: function set(value) {
        this.updateGruppenFilter({
          gruppe: 'abrechnung',
          filter: 'pzr',
          info: {
            anzeigen: value,
            index: 0
          }
        });
      }
    }
  },
  methods: {
    updateGruppenFilter: function updateGruppenFilter(data) {
      editSettingsStore.commit.updateGruppenFilter(data);
    }
  }
});